<template>
  <v-card class="mx-auto" max-width="580" flat>
    <v-card dark flat>
      <v-card-title class="pa-2 indigo">
        <h3 class="text-h6 font-weight-light text-center grow">
          {{ title }}
        </h3>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog v-model="dialog" persistent max-width="550">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              absolute
              bottom
              v-bind="attrs"
              v-on="on"
              right
              fab
              color="green"
            >
              <v-icon>mdi-file-document-plus</v-icon>
            </v-btn>
          </template>
          <v-card width="600">
            <v-card-title class="text-h5">
              Ajouter un commentaire
            </v-card-title>
            <v-card-text>
              <v-select
                v-model="comment_type"
                label="Type"
                :rules="[(v) => !!v || 'Type obligatoire']"
                :items="comment_types"
                :item-value="'id'"
                :item-text="'label'"
              >
              </v-select>
              <v-textarea
                v-model="comment"
                :rules="[(v) => !!v || 'Commentaire obligatoire']"
                label="Commentaire"
              >
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialog = false"> Annuler </v-btn>
              <v-btn :loading="progress" color="primary" @click="save_comment">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>
    <v-card-text class="py-0">
      <v-card flat>
        <v-card-text></v-card-text>
      </v-card>
      <v-timeline align-top dense v-if="comments.length > 0">
        <v-timeline-item
          v-for="(item, i) in comments"
          :key="i"
          fill-dot
          small
          :icon="item.icon"
        >
          <strong>{{ item.comment }}</strong>
          {{
            " Par " + item.user_name + " le " + datefr(item.created_at, true)
          }}
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import CREATE_NOTE from "../graphql/CREATE_NOTE.gql";
export default {
  props: {
    title: String,
    comments: Array,
    document_type: Number,
    doc_id: Number,
  },
  data() {
    return {
      dialog: false,
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      comment_types: [
        { id: "1", label: "Phone", icon: "mdi-phone-classic" },
        { id: "2", label: "Email", icon: "mdi-email" },
        { id: "3", label: "Meeting", icon: "mdi-account-multiple" },
        { id: "4", label: "Info", icon: "mdi-account-tie-voice" },
      ],
    };
  },
  created() {},
  methods: {
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save_comment() {
      if (this.$refs.form.validate()) {
        let i = this.comment_types.findIndex(
          (elm) => elm.id == this.comment_type
        );
        let icon;
        if (i >= 0) icon = this.comment_types[i].icon;
        let v = {
          note: {
            document_type: this.document_type,
            document_id: parseInt(this.doc_id),
            comment: this.comment,
            comment_type: parseInt(this.comment_type),
            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let id = await this.maj(CREATE_NOTE, v);
        this.comments.push({
          id: id,
          comment: this.comment,
          icon: icon,
          user_name: this.$store.state.me.name,
          comment_type: parseInt(this.comment_type),
          create_uid: parseInt(this.$store.state.me.id),
          created_at: this.$store.state.today,
        });
        this.comment = null;
        this.dialog = false;
      }
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
  },
};
</script>
